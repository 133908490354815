import { motion } from 'framer-motion';
import styled from 'styled-components';

const SLIDER_BAR_WIDTH = 0.13;
const SLIDER_CIRCLE_DIAMETER = 3;
const LABEL_FONT_SIZE = 1;
const LABEL_LINE_GAP = 0.3;
const LABEL_FONT_WEIGHT = 500;
const ARROW_SIZE = 25;
const SIDE_PANEL_WIDTH = 25;

const transition = {
  ease: [0.820, 0.000, 0.195, 1.000],
  duration: 2,
  loop: Infinity,
};

export const pulseLeft = {
  enter: {
    left: '40%',
    opacity: 0,
    transition,
  },
  animate: {
    left: ['40%', '20%', '20%'],
    opacity: [0, 1, 0],
    transition,
  },
};

export const pulseRight = {
  enter: {
    right: '40%',
    opacity: 0,
    transition,
  },
  animate: {
    right: ['40%', '20%', '20%'],
    opacity: [0, 1, 0],
    transition,
  },
};


export const Slider = styled.div`
    height: 100%;
    width: ${SLIDER_BAR_WIDTH}rem;
    background-color: black;
    position: relative;
`;


export const LeftArrow = styled(motion.div)`
  width: ${ARROW_SIZE}%;
  height: ${ARROW_SIZE}% !important;
  border: 3px solid white;
  border-top: none;
  border-right: none;
  position: absolute;
  top: ${(SLIDER_CIRCLE_DIAMETER / 2) - (SLIDER_CIRCLE_DIAMETER * (ARROW_SIZE / 200))}rem;
  transform: rotate(45deg);
  left: 0%;
`;
export const RightArrow = styled(motion.div)`
  width: ${ARROW_SIZE}%;
  height: ${ARROW_SIZE}% !important;
  border: 3px solid white;
  border-bottom: none;
  border-left: none;
  position: absolute;
  top: ${(SLIDER_CIRCLE_DIAMETER / 2) - (SLIDER_CIRCLE_DIAMETER * (ARROW_SIZE / 200))}rem;
  transform: rotate(45deg);
  right: 0%;
`;


export const Circle = styled.div`
    height: ${SLIDER_CIRCLE_DIAMETER}rem !important;
    width: ${SLIDER_CIRCLE_DIAMETER}rem;
    background-color: black;
    position: relative;
    top: 50%;
    transform: translate(calc(-50% + ${SLIDER_BAR_WIDTH / 2}rem), -50%);
    border-radius: 50%;
`;

export const LeftLabel = styled.div`
  position: absolute;
  top: 0;
  left: auto;
  right: 110%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  & p {
    padding: 0.05rem 0.2rem;
    background-color: black;
    color: white;
    font-family: 'Red Hat Display';
    font-size: ${LABEL_FONT_SIZE}rem;
    text-transform: uppercase;
    margin-bottom: ${LABEL_LINE_GAP}rem;
    font-weight: ${LABEL_FONT_WEIGHT};
  }
`;

export const RightLabel = styled.div`
  position: absolute;
  top: 0;
  right: auto;
  left: 110%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  & p {
    padding: 0.05rem 0.2rem;
    background-color: black;
    color: white;
    font-family: 'Red Hat Display';
    font-size: ${LABEL_FONT_SIZE}rem;
    text-transform: uppercase;
    margin-bottom: ${LABEL_LINE_GAP}rem;
    font-weight: ${LABEL_FONT_WEIGHT};
  }
`;


export const SidePanel = styled.div`
  width: ${SIDE_PANEL_WIDTH}%;
  background-image: linear-gradient(to top, #2b2425 0%, #352e2f 100%);
  height: 100%;
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const TTContainer = styled.div`
  height: 100%;
  width: ${100 - SIDE_PANEL_WIDTH}%;
  cursor: pointer;
  & div {
    height: 100%;
  }
`;
