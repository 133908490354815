import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import HoverDot from 'src/components/desktop/hoverDot/hoverDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import Video from 'src/assets/videos/chapter_5/sub_2/5-2-1-bg.webm';
import Mp4 from 'src/assets/videos/chapter_5/sub_2/5-2-1-bg.mp4';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer2: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-flag.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'horizontal' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId] && assetQuery[modalId].childImageSharp.fixed });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };
  return (
    <SlideLarge>
      <ParallaxMordo column>
        <Mordo
          mainscene
          background={{ color: 'rgba(19,35,46, 0.25)' }}

          move={{ x: -20, y: -10 }}
        >
          <BackgroundAnimation src={Video} mp4={Mp4} responsive />
        </Mordo>

        <Mordo
          scenelayer={{ fromLeft: '12%', fromBottom: '40%' }}
          scene={2}
          move={{ x: 5, y: 5 }}
          opacity={1}
          customStyle={{ overflow: 'visible' }}
        >
          {query.hoverDot.exports.hoverInfo.map((dot) => <HoverDot key={dot.identifier} alternative insideText={dot.insideText} positionLeft={dot.positionLeft} positionTop={dot.positionTop} main={dot.main} tooltip={dot.tooltip} clickDot={() => clickHandler(dot.identifier)} />)}
        </Mordo>
        <Mordo
          scene={1}
          move={{ x: -5, y: -5 }}
          sceneLayer={{ fromBottom: '20%' }}
        >
          <LeftColContent body={query.mdx.body} style={{ marginTop: '10%', marginLeft: '0%' }} />
        </Mordo>

      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
