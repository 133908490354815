import React from 'react';
import SliderContent from 'src/components/mobile/sliderContent/sliderContent';
import HoverDot from 'src/components/mobile/hoverDot/hoverDot';
import { BlockContainer } from '../../_styles';

const SubSlider = ({ style, query, clickHandler }) => (
  <BlockContainer style={{ padding: '4rem 0', ...style }}>
    <SliderContent>
      {query.hoverDot.exports.hoverInfo.map((dot) => <HoverDot key={dot.identifier} alternative insideText={dot.insideText} clickDot={() => clickHandler(dot.identifier)} />)}
    </SliderContent>
  </BlockContainer>
);

export default SubSlider;
