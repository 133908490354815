import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_5/sub_2/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      before: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "slide-5-2-2-before" }
        }
      ) {
        body
      }
      after: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "slide-5-2-2-after" }
        }
      ) {
        body
      }
      description: mdx(
        frontmatter: { language: { eq: "FR" }, title: { eq: "slide-5-2-2" } }
      ) {
        body
      }
      leftText: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "slide-5-2-2-left" }
        }
      ) {
        body
      }
      rightText: mdx(
        frontmatter: {
          language: { eq: "FR" }
          title: { eq: "slide-5-2-2-right" }
        }
      ) {
        body
      }
      audioDesc: mdx(
        frontmatter: { language: { eq: "FR" }, title: { eq: "slide-5-2-2-audio" } }
      ) {
        excerpt(pruneLength: 100000)
      }
    }
  `);
  return <Template query={query} />;
};

export default Slide;
