import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/fr/chapter_5/sub_2/slide1';
import Slide2 from 'src/slides/desktop/fr/chapter_5/sub_2/slide2';
import WrapperMobile from 'src/slides/mobile/fr/chapter_5/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="La bataille qui a sauvé l'Europe et le monde | Bataille de Varsovie" lang="fr" description="Le succès près de Varsovie a assuré la paix à l'Europe pendant 20 ans. " />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="La bataille qui a sauvé l'Europe et le monde | Bataille de Varsovie" lang="fr" description="Le succès près de Varsovie a assuré la paix à l'Europe pendant 20 ans. " />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
