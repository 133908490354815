import React from 'react';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Twentytwenty from 'react-twentytwenty';
import BackgroundImage from 'gatsby-background-image';
import { getLangFromPath } from 'src/utils';
import { BlockContainer, ChpaterTitle } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

import {
  Slider,
  Circle,
  RightArrow,
  LeftArrow,
  RightLabel,
  LeftLabel,
  TTContainer,
  SidePanel,
  pulseLeft,
  pulseRight,
} from './styles';

const SliderWithCircle = ({ leftLabel, rightLabel }) => (
  <Slider>
    <Circle>
      <LeftArrow initial="enter" animate="animate" variants={pulseLeft} />
      <RightArrow initial="enter" animate="animate" variants={pulseRight} />
      <MDXProvider
        components={{
          p: (props) => <p {...props} tabIndex="0" />,
        }}
      >
        <LeftLabel>
          <MDXRenderer>{leftLabel}</MDXRenderer>
        </LeftLabel>
        <RightLabel>
          <MDXRenderer>{rightLabel}</MDXRenderer>
        </RightLabel>
      </MDXProvider>
    </Circle>
  </Slider>
);

const Slide = ({ query, audio = 'Julian Marchlewski' }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      before: file(relativePath: { eq: "chapter_5/sub_2/5-2-1-mobile__left.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      after: file(relativePath: { eq: "chapter_5/sub_2/5-2-1-mobile__right.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      audioImage: file(
        relativePath: { eq: "chapter_5/sub_2/marchlewski.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <BlockContainer height="1700px" bg="#231f20">
      <TextAnimation body={query.mdx.body} containerStyle={{ height: '20%' }} />
      <TTContainer>
        <Twentytwenty
          left={(
            <BackgroundImage fluid={assetQuery.before.childImageSharp.fluid}>
              <TextAnimation body={query.leftText.body} noMotion />
            </BackgroundImage>
          )}
          right={(
            <BackgroundImage fluid={assetQuery.after.childImageSharp.fluid}>
              <TextAnimation body={query.rightText.body} noMotion />
            </BackgroundImage>
          )}
          slider={(
            <SliderWithCircle
              leftLabel={query.before.body}
              rightLabel={query.after.body}
            />
          )}
        />
      </TTContainer>
      <AudioPlayer
        audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/522marchlewski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/522marchlewski.mp4`]}
        audioImage={assetQuery.audioImage.childImageSharp.fluid}
        audioDescription={audio}
        size={2}
        style={{ margin: '10rem 0' }}
      />
    </BlockContainer>
  );
};

export default Slide;
