import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Twentytwenty from 'react-twentytwenty';
import BackgroundImage from 'gatsby-background-image';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { getLangFromPath } from 'src/utils';
import { SlideLarge } from '../../_styles';
import {
  Slider, Circle, RightArrow, LeftArrow, RightLabel, LeftLabel, TTContainer, SidePanel, pulseLeft, pulseRight,
} from './styles';
import { mediaServer } from '../../../../../../globals';

export const SliderWithCircle = ({ leftLabel, rightLabel }) => (
  <Slider>
    <Circle>
      <LeftArrow
        initial="enter"
        animate="animate"
        variants={pulseLeft}
      />
      <RightArrow
        initial="enter"
        animate="animate"
        variants={pulseRight}
      />
      <MDXProvider components={{
        p: (props) => <p {...props} tabIndex="0" />,
      }}
      >
        <LeftLabel>
          <MDXRenderer>
            {leftLabel}
          </MDXRenderer>
        </LeftLabel>
        <RightLabel>
          <MDXRenderer>
            {rightLabel}
          </MDXRenderer>
        </RightLabel>
      </MDXProvider>
    </Circle>
  </Slider>
);


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    before: file(relativePath: { eq: "chapter_5/sub_2/disinformation.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
    after: file(relativePath: { eq: "chapter_5/sub_2/truth.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
    audioImage: file(relativePath: { eq: "chapter_5/sub_2/marchlewski.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    },
  }
`);
  return (
    <SlideLarge>
      <div css={{ display: 'flex', height: '100%' }}>
        <TTContainer>
          <Twentytwenty
            left={(
              <BackgroundImage fluid={assetQuery.before.childImageSharp.fluid}>
                <LeftColContent body={query.leftText.body} style={{ paddingLeft: '0' }} />
              </BackgroundImage>
)}
            right={(
              <BackgroundImage fluid={assetQuery.after.childImageSharp.fluid}>
                <LeftColContent body={query.rightText.body} style={{ paddingLeft: '0' }} />
              </BackgroundImage>
)}
            slider={<SliderWithCircle leftLabel={query.before.body} rightLabel={query.after.body} />}
          />
        </TTContainer>
        <SidePanel>
          <LeftColContent body={query.description.body} style={{ paddingLeft: '14%', height: 'auto' }} />
          <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/522marchlewski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/522marchlewski.mp4`]} audioImage={assetQuery.audioImage.childImageSharp.fluid} style={{ width: 'auto' }} audioDescription={query.audioDesc.excerpt} textStyle={{ width: '350%' }} />
        </SidePanel>
      </div>
    </SlideLarge>
  );
};


export default Slide;
